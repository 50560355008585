<template>
  <Attention />
</template>

<script>
import Attention from './Attention.vue'

export default {
  components: {
    Attention
  },
  layout: 'account'
}
</script>
