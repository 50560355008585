<template>
  <section class="attention-container" style="display: flex; flex-direction: column; align-items: center;">
    <div
      style="margin-top: 20px; height: 64px; font-size: 30px; font-weight: 500; color: #333333; line-height: 64px;"
    >
      {{ $t('attention.title') }}
    </div>
    <div
      style="font-size: 16px; font-weight: 400; color: rgba(0,0,0,0.65); line-height: 24px;"
    >
      {{ $t('attention.secTitle') }}
    </div>
    <a-spin :spinning="applyLoading" class="flex-x" style="width: 100%; margin-top: 20px;">
      <div class="form">
        <a-form-model
          ref="form"
          :model="formModel"
          :rules="formRule"
          :label-col="{ span: 5 }"
          :wrapper-col="{ span: 19 }"
          :colon="false"
        >
          <a-form-model-item v-if="!isEng" :label="$t('attention.form.attention.label')" prop="attention">
            <a-radio-group v-model="formModel.attention" @change="handleAttentionChange">
              <a-radio value="production">
                {{ $t('attention.form.attention.radio1') }}
              </a-radio>
              <a-radio value="solution">
                {{ $t('attention.form.attention.radio2') }}
              </a-radio>
            </a-radio-group>
          </a-form-model-item>
          <a-form-model-item :label="$t('attention.form.appellation.label')" prop="appellation">
            <a-input
              v-model="formModel.appellation"
              :placeholder="$t('attention.form.appellation.placeholder')"
              :max-length="50"
              style="width: 100%; height: 32px;"
            />
          </a-form-model-item>
          <a-form-model-item :label="$t('attention.form.companyName.label')" prop="companyName">
            <a-input
              v-model="formModel.companyName"
              :placeholder="$t('attention.form.companyName.placeholder')"
              :max-length="50"
              style="width: 100%; height: 32px;"
            />
          </a-form-model-item>
          <a-form-model-item :label="$t('attention.form.position.label')" prop="position">
            <a-input
              v-model="formModel.position"
              :placeholder="$t('attention.form.position.placeholder')"
              :max-length="50"
              style="width: 100%; height: 32px;"
            />
          </a-form-model-item>
          <a-form-model-item v-if="!isEng" :label="$t('attention.form.department.label')" prop="department">
            <a-input
              v-model="formModel.department"
              :placeholder="$t('attention.form.department.placeholder')"
              :max-length="50"
              style="width: 100%; height: 32px;"
            />
          </a-form-model-item>
          <a-form-model-item :label="$t('attention.form.contact.label')" prop="contact">
            <div>
              <a-input
                v-model="formModel.contact.phoneNumber"
                :placeholder="$t('attention.form.contact.placeholder1')"
                :max-length="20"
                style="width: 100%; height: 32px;"
              />
              <a-input
                v-if="formModel.attention === 'production'"
                v-model="formModel.contact.email"
                :placeholder="$t('attention.form.contact.placeholder2')"
                :max-length="50"
                style="width: 100%; height: 32px; margin-top: 25px;"
              />
            </div>
          </a-form-model-item>
          <template v-if="formModel.attention === 'solution'">
            <a-form-model-item :label="$t('attention.form.demo.label')" prop="demo">
              <a-radio-group v-model="formModel.demo">
                <a-radio :value="true">
                  {{ $t('attention.form.demo.radio1') }}
                </a-radio>
                <a-radio :value="false">
                  {{ $t('attention.form.demo.radio2') }}
                </a-radio>
              </a-radio-group>
            </a-form-model-item>
            <template v-if="formModel.demo">
              <a-form-model-item :label="$t('attention.form.funcDescription.label')" prop="funcDescription">
                <a-input
                  v-model="formModel.funcDescription"
                  :placeholder="$t('attention.form.funcDescription.placeholder')"
                  :max-length="50"
                  style="width: 100%; height: 32px;"
                >
                  <div slot="suffix" class="suffix-text">
                    {{ formModel.funcDescription.length }}/50
                  </div>
                </a-input>
              </a-form-model-item>
              <a-form-model-item :label="$t('attention.form.target.label')" prop="target">
                <a-input
                  v-model="formModel.target"
                  :placeholder="$t('attention.form.target.placeholder')"
                  :max-length="50"
                  style="width: 100%; height: 32px;"
                >
                  <div slot="suffix" class="suffix-text">
                    {{ formModel.target.length }}/50
                  </div>
                </a-input>
              </a-form-model-item>
              <a-form-model-item :label="$t('attention.form.projectRequire.label')" prop="projectRequire">
                <a-textarea
                  v-model="formModel.projectRequire"
                  :auto-size="{ minRows: 4, maxRows: 4 }"
                  :max-length="100"
                  :placeholder="$t('attention.form.projectRequire.placeholder')"
                  style="width: 100%;"
                />
                <div class="char-num-limit">
                  {{ $t('attention.form.projectRequire.limit') }}{{ formModel.projectRequire.length || 0 }}/100
                </div>
              </a-form-model-item>
            </template>

            <a-form-model-item :label="$t('attention.form.needDoc.label')" prop="needDoc">
              <a-radio-group v-model="formModel.needDoc">
                <a-radio :value="true">
                  {{ $t('attention.form.needDoc.radio1') }}
                </a-radio>
                <a-radio :value="false">
                  {{ $t('attention.form.needDoc.radio2') }}
                </a-radio>
              </a-radio-group>
            </a-form-model-item>
            <template v-if="formModel.needDoc">
              <a-form-model-item :label="$t('attention.form.docList.label')" prop="docList">
                <a-checkbox-group v-model="formModel.docList" :options="docOptions" />
              </a-form-model-item>
              <a-form-model-item :label="$t('attention.form.receiveEmail.label')" prop="receiveEmail">
                <a-input
                  v-model="formModel.receiveEmail"
                  :placeholder="$t('attention.form.receiveEmail.placeholder')"
                  style="width: 100%; height: 32px;"
                >
                </a-input>
              </a-form-model-item>
            </template>
          </template>
          <a-form-model-item :label="$t('attention.form.message.label')" prop="message">
            <a-textarea
              v-model="formModel.message"
              :auto-size="{ minRows: 4, maxRows: 4 }"
              :max-length="100"
              :placeholder="$t('attention.form.message.placeholder')"
              style="width: 100%;"
            />
            <div class="char-num-limit">
              {{ $t('attention.form.message.limit') }}{{ formModel.message.length || 0 }}/100
            </div>
          </a-form-model-item>
        </a-form-model>
        <a-button
          class="btn"
          style="width: 100%;height: 40px;"
          type="primary"
          :loading="applyLoading"
          @click="handleIntentions"
        >
          <span style="font-size: 16px; font-family: PingFang SC;">{{ $t('attention.button.submit') }}</span>
        </a-button>
        <div class="submit-tip">
          <p v-if="!isEng">
            {{ $t('attention.button.tip1') }}
          </p>
          <p>{{ $t('attention.button.tip2') }}</p>
        </div>
      </div>
    </a-spin>
  </section>
</template>

<script>
import { regComplexPhone, regEmail2 } from '@/utils/regexp'
import pageMixin from '@/mixins/pageMixin'

export default {
  mixins: [pageMixin],
  layout: 'account',
  data () {
    return {
      applyLoading: false,
      formModel: {
        attention: 'production',
        appellation: '',
        companyName: '',
        demo: false,
        funcDescription: '',
        position: '',
        projectRequire: '',
        target: '',
        department: '',
        contact: {
          phoneNumber: '',
          email: ''
        },
        message: '',
        needDoc: false,
        receiveEmail: '',
        docList: []
      },
      docOptions: [{
        label: this.$i18n.t('attention.form.docList.options.label1'),
        value: 0
      }, {
        label: this.$i18n.t('attention.form.docList.options.label2'),
        value: 1
      }, {
        label: this.$i18n.t('attention.form.docList.options.label3'),
        value: 2
      }, {
        label: this.$i18n.t('attention.form.docList.options.label4'),
        value: 3
      }],
      formRule: {
        appellation: { required: true, message: this.$i18n.t('attention.form.rule.appellation') },
        companyName: { required: true, message: this.$i18n.t('attention.form.rule.companyName') },
        contact: { required: true, validator: this.contactValidator },
        funcDescription: { required: true, message: this.$i18n.t('attention.form.rule.funcDescription') },
        target: { required: true, message: this.$i18n.t('attention.form.rule.target') },
        docList: { required: true, message: this.$i18n.t('attention.form.rule.docList') },
        receiveEmail: { required: true, validator: this.emailValidator },
        message: { required: true, message: this.$i18n.t('attention.form.rule.message') }
      }
    }
  },
  computed: {
    // 判断是否英文页面
    isEng () {
      return this.$route.fullPath.includes('/en/')
    }
  },
  created () {
    this.initFormModel()
  },
  methods: {
    initFormModel () {
      let attention = 'production'
      if (this.$route.query.bz === 'solution') {
        attention = 'solution'
      }
      this.formModel = {
        attention,
        appellation: '',
        companyName: '',
        demo: false,
        funcDescription: '',
        position: '',
        projectRequire: '',
        target: '',
        department: '',
        contact: {
          phoneNumber: '',
          email: ''
        },
        message: '',
        needDoc: false,
        receiveEmail: '',
        docList: []
      }
    },
    handleAttentionChange () {
      this.$refs.form.clearValidate()
    },
    emailValidator (rule, value, callback) {
      if (value) {
        if (
          regEmail2.test(value)
        ) {
          callback()
        } else {
          callback(new Error(this.$i18n.t('attention.form.rule.receiveEmail.error1')))
        }
      } else {
        callback(new Error(this.$i18n.t('attention.form.rule.receiveEmail.error2')))
      }
    },
    contactValidator (rule, value, callback) {
      if (this.formModel.attention === 'production') {
        if (value.phoneNumber === '' && value.email === '') {
          callback(new Error(this.$i18n.t('attention.form.rule.contact.error1')))
        } else if (value.phoneNumber) {
          if (
            regComplexPhone.test(value.phoneNumber.toString().replace(/\s/g, ''))
          ) {
            callback()
          } else {
            callback(new Error(this.$i18n.t('attention.form.rule.contact.error2')))
          }
        } else if (value.email) {
          if (
            regEmail2.test(value.email)
          ) {
            callback()
          } else {
            callback(new Error(this.$i18n.t('attention.form.rule.contact.error3')))
          }
        } else {
          callback()
        }
      } else if (value.phoneNumber === '') {
        callback(new Error(this.$i18n.t('attention.form.rule.contact.error4')))
      } else if (
        regComplexPhone.test(value.phoneNumber.toString().replace(/\s/g, ''))
      ) {
        callback()
      } else {
        callback(new Error(this.$i18n.t('attention.form.rule.contact.error2')))
      }
    },
    // 触发提交
    async handleIntentions () {
      const valid = await this.$refs.form.validate()
      if (!valid) {
        return
      }
      this.handleSubmit()
    },
    // 发起提交请求
    handleSubmit () {
      this.applyLoading = true
      const { submitFn, params, err } = this.useSubmit(this.formModel.attention)
      // 如果类型错误，则终端提交流程
      if (err) { this.applyLoading = false; return }
      submitFn(params)
        .then(res => {
          this.applyLoading = false
          this.$modal.success({
            title: this.$i18n.t('attention.modal.success')
          })
          this.initFormModel()
        })
        .catch(e => {
          this.applyLoading = false
          this.$modal.error({
            title: this.$i18n.t('attention.modal.error')
          })
        })
    },
    // 获取业务意向对应的提交方法和body体
    useSubmit (type) {
      let submitFn, params
      if (type === 'production') {
        submitFn = this.api.purchaseintentions
        params = {
          appellation: this.formModel.appellation,
          companyName: this.formModel.companyName,
          position: this.formModel.position,
          department: this.formModel.department,
          phoneNumber: this.formModel.contact.phoneNumber,
          email: this.formModel.contact.email,
          message: this.formModel.message
        }
      } else if (type === 'solution') {
        submitFn = this.api.submitSolution
        params = {
          customerInfo: {
            name: this.formModel.appellation,
            company: this.formModel.companyName,
            position: this.formModel.position,
            department: this.formModel.department,
            phone: this.formModel.contact.phoneNumber
          },
          remark: this.formModel.message
        }
        if (this.formModel.demo) {
          params = {
            ...params,
            ...{
              demoInfo: {
                featureDescription: this.formModel.funcDescription,
                purpose: this.formModel.target,
                requirement: this.formModel.projectRequire
              }
            }
          }
        }
        if (this.formModel.needDoc) {
          params = {
            ...params,
            ...{
              documentInfo: {
                documentTypes: this.formModel.docList,
                receiveEmail: this.formModel.receiveEmail
              }
            }
          }
        }
      } else {
        this.$modal.warn({
          title: this.$i18n.t('attention.modal.warn')
        })
        return { err: true }
      }
      return { submitFn, params }
    }
  },
  head () {
    return {
      title: this.$i18n.t('attention.title')
    }
  }
}
</script>

<style lang="less" scoped>
.form {
  margin: auto;
}

.submit-tip {
  margin-top: 32px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.45);
  line-height: 22px;
  text-align: center;
  padding-bottom: 50px;
}

.suffix-text {
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
}
.char-num-limit {
  position: absolute;
  bottom: -40px;
  right: 0;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.45);

}
</style>
<style lang="less">
.attention-container {

  padding: 0 30px;

  .ant-input {
    border-radius: 2px;
  }

  .ant-input-affix-wrapper .ant-input:not(:last-child) {
    padding-right: 50px;
  }
}
</style>
